import * as React from "react"
import { BackButton, Heading, Wedding } from "../../components"

const WeddingPage = () => {
  return (
    <div className="container">
      <Heading title="Wedding" cursive="Content Creation" />
      <BackButton />
      <Wedding />
    </div>
  )
}

export default WeddingPage

export const Head = () => <title>TMOY || Weddings</title>